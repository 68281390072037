import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FeaturesEnum } from '../features-enum';
import { TestItemsStatisticsState, testItemStatisticsAdapter } from '../states/test-item-statistics.state';

const { selectAll } = testItemStatisticsAdapter.getSelectors();

const selectTestItemTreeFeature = createFeatureSelector<TestItemsStatisticsState>(FeaturesEnum.statistics);

export const selectAllTestItemStatistics = createSelector(selectTestItemTreeFeature, selectAll);

export const selectIsStatisticsUpdating = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.isUpdating);

export const selectLastStatisticsUpdate = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.lastUpdate);
