import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { testItemAdapter, TestItemsState } from '../states/test-items.state';
import { FeaturesEnum } from '../features-enum';
import { TestItem, TestItemTypeEnum } from 'src/api/testrunner/models';

const { selectEntities, selectAll } = testItemAdapter.getSelectors();

const selectTestItemTreeFeature = createFeatureSelector<TestItemsState>(FeaturesEnum.testItems);

export const selectTestItems = createSelector(selectTestItemTreeFeature, selectEntities);

export const selectAllTestItems = createSelector(selectTestItemTreeFeature, selectAll);

export const selectLayoutGroups = createSelector(selectAllTestItems, (testItems) =>
  testItems.filter((testItem) => [TestItemTypeEnum.LayoutGroup].includes(testItem.testItemType))
);

export const selectLayoutTestItems = createSelector(selectAllTestItems, (testItems) =>
  testItems
    .filter((testItem) =>
      [TestItemTypeEnum.Kano, TestItemTypeEnum.Mobile, TestItemTypeEnum.Page, TestItemTypeEnum.WebSite].includes(testItem.testItemType)
    )
    .sort((item1: TestItem, item2: TestItem) => {
      if (item1?.parentGroupId === item2?.parentGroupId) {
        return item1.order - item2.order;
      } else {
        return 0;
      }
    })
);

export const selectParentPageItem = (
  testItemId?: string
): MemoizedSelector<object, TestItem | undefined, DefaultProjectorFn<TestItem | undefined>> =>
  createSelector(selectTestItems, (entities) => {
    let testItem = entities[testItemId ?? ''];
    while (testItem) {
      if ([
        TestItemTypeEnum.Mobile,
        TestItemTypeEnum.Page,
        TestItemTypeEnum.WebSite,
        TestItemTypeEnum.Kano
      ].includes(testItem.testItemType)) {
        break;
      }
      testItem = entities[testItem.parentGroupId ?? ''];
    }
    return testItem;
  });

export const selectTestItemById = (id: string): MemoizedSelector<object, TestItem | undefined, DefaultProjectorFn<TestItem | undefined>> =>
  createSelector(selectTestItems, (entities) => entities[id]);

export const selectTestItemsByIds = (
  ids: string[]
): MemoizedSelector<object, (TestItem | undefined)[], DefaultProjectorFn<(TestItem | undefined)[]>> =>
  createSelector(selectTestItems, (entities) => ids.map((id) => entities[id]));
