import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { TestItem } from 'src/api/testrunner/models/test-item';

export const testItemAdapter: EntityAdapter<TestItem> = createEntityAdapter<TestItem>({
  selectId: (item) => item.id as string,
  sortComparer: false,
});

export interface TestItemsState extends EntityState<TestItem> {}
