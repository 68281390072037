/* tslint:disable */
/* eslint-disable */
export enum TestItemStatisticsTypeEnum {
  LogicResultTrueAndCompleted = 'LogicResultTrueAndCompleted',
  LogicResultFalseAndCompleted = 'LogicResultFalseAndCompleted',
  TotalAnswered = 'TotalAnswered',
  AnsweredAndCompleted = 'AnsweredAndCompleted',
  AnsweredAndNotCompleted = 'AnsweredAndNotCompleted',
  TestEndByQuota = 'TestEndByQuota'
}
