/* tslint:disable */
/* eslint-disable */
export enum FabDictionaryItemSystemNameEnum {
  Undefined = 'Undefined',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Firefox = 'Firefox',
  FirefoxMobile = 'FirefoxMobile',
  Edge = 'Edge',
  Msie = 'MSIE',
  Opera = 'Opera',
  Netscape = 'Netscape',
  ChromeMobile = 'ChromeMobile',
  SafariMobile = 'SafariMobile',
  UcBrowser = 'UCBrowser',
  QqBrowser = 'QQBrowser',
  IeMobile = 'IEMobile',
  Mathon = 'Mathon',
  Konqueror = 'Konqueror',
  YandexBrowser = 'YandexBrowser',
  Atom = 'Atom',
  Brave = 'Brave',
  ComodoDragon = 'ComodoDragon',
  Iron = 'Iron',
  Vivaldi = 'Vivaldi',
  Aloha = 'Aloha',
  AndroidBrowser = 'AndroidBrowser',
  Dolphin = 'Dolphin',
  DuckDuckGoPrivacyBrowser = 'DuckDuckGoPrivacyBrowser',
  MiuiBrowser = 'MIUIBrowser',
  MintBrowser = 'MintBrowser',
  Puffin = 'Puffin',
  Windows = 'Windows',
  Macos = 'Macos',
  Ubuntu = 'Ubuntu',
  Linux = 'Linux',
  Android = 'Android',
  Ios = 'IOS',
  Blackberry = 'Blackberry',
  Bada = 'Bada',
  Webos = 'Webos'
}
