/* tslint:disable */
/* eslint-disable */
export enum TestItemResultStatusEnum {
  Intermediate = 'Intermediate',
  IgnoredByUser = 'IgnoredByUser',
  TimeOut = 'TimeOut',
  SubmittedByUser = 'SubmittedByUser',
  SkippedByLogic = 'SkippedByLogic',
  SubmittedByLogic = 'SubmittedByLogic',
  Failed = 'Failed',
  BroadcastCanceled = 'BroadcastCanceled'
}
