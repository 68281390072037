/* tslint:disable */
/* eslint-disable */
export enum ScaleTypeEnum {
  Digit = 'Digit',
  Sliders = 'Sliders',
  Rating = 'Rating',
  EmotionsColor = 'EmotionsColor',
  EmotionsGray = 'EmotionsGray',
  RatingStar = 'RatingStar',
  RatingHeart = 'RatingHeart',
  RatingLike = 'RatingLike',
  NpsRadioButton = 'NPSRadioButton',
  NpsSquare = 'NPSSquare',
  Standard = 'Standard',
}
