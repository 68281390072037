import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import {
  FabDictionary,
  FabDictionaryItem,
  FabDictionaryItemStateEnum,
  FabDictionaryItemSystemNameEnum,
  HeaderShowStatusEnum,
  StartSessionStatusEnum,
  TestTypeEnum,
  ThemeColorEnum,
} from 'src/api/testrunner/models';
import { TestStatusEnum } from 'src/app/models/test-status.enum';
import { ExtensionStatusEnum } from '../../models/extension-status-enum';
import { LogicEnvironmentParams } from '../../models/logic-environment-params';
import { MobileAppStatusEnum } from '../../models/mobile-app-status-enum';
import { FeaturesEnum } from '../features-enum';
import { ModeratedState } from '../states/moderated.state';
import { TestRunnerState } from '../states/test-runner.state';
import { selectAllTestItemStatistics } from './test-item-statistics.selectors';
import { selectLayoutTestItems, selectTestItems } from './test-items.selectors';

const selectTestItemTreeFeature = createFeatureSelector<TestRunnerState>(FeaturesEnum.testRunner);
const selectModeratedFeature = createFeatureSelector<ModeratedState>(FeaturesEnum.moderated);

export const selectAllDictionaries = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState?.testRunnerStartData?.dictionaries ?? [],
);
export const selectBackendErrors = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.backendErrors);
export const selectCompleteTestInfo = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.completeTestInfo);
export const selectCurrentBrowserInfo = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) =>
    (testRunnerState.testRunnerStartData?.browsersDictionary?.fabDictionaryItems || []).find(
      (dictionaryItem) => dictionaryItem.systemName === testRunnerState.testRunnerStartData?.browserSystemName,
    ) ?? null,
);
export const selectCurrentOsInfo = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) =>
    (testRunnerState.testRunnerStartData?.operationSystemsDictionary?.fabDictionaryItems || []).find(
      (dictionaryItem) => dictionaryItem.systemName === testRunnerState.testRunnerStartData?.osSystemName,
    ) ?? null,
);
export const selectIsDarkTheme = createSelector(selectTestItemTreeFeature, (testRunnerState) =>
  testRunnerState.testRunnerStartData?.test?.themeColor === ThemeColorEnum.Dark ? 'arya-green' : 'fabuza',
);
export const selectDictionaryById = (
  id: string,
): MemoizedSelector<object, FabDictionary | null, DefaultProjectorFn<FabDictionary | null>> =>
  createSelector(
    selectTestItemTreeFeature,
    (testRunnerState) => (testRunnerState.testRunnerStartData?.dictionaries || []).find((item) => id === item.id) ?? null,
  );

export const selectDictionaryItemsById = (
  id: string,
): MemoizedSelector<object, FabDictionaryItem[], DefaultProjectorFn<FabDictionaryItem[]>> =>
  createSelector(selectTestItemTreeFeature, (testRunnerState) => {
    const dictionary = (testRunnerState.testRunnerStartData?.dictionaries || []).find((item) => id === item.id);
    const dictionaryItems =
      dictionary && dictionary.fabDictionaryItems
        ? dictionary.fabDictionaryItems.filter((item) => item.state === FabDictionaryItemStateEnum.Item)
        : [];

    return dictionaryItems;
  });

export const selectExtensionError = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.extensionErrors);
export const selectExtensionStatus = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.extensionStatus);
export const selectIsExtensionInstalled = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.isExtensionInstalled,
);
export const selectIsHeaderVisible = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.testRunnerStartData?.test?.headerShowStatus === HeaderShowStatusEnum.Show,
);
export const selectIsMobileAppInstalled = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.isMobileAppInstalled,
);
export const selectMobileAppStatus = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.mobileAppStatus);
export const selectIsMobileDevice = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => !!testRunnerState.testRunnerStartData?.isMobileDevice,
);
export const selectIsMobileInProgress = (isMobile: boolean): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(selectTestItemTreeFeature, (testRunnerState) => {
    return (
      isMobile &&
      (!testRunnerState.isMobileAppInstalled ||
        (testRunnerState?.mobileAppStatus !== MobileAppStatusEnum.TaskCompleted &&
          testRunnerState?.mobileAppStatus !== MobileAppStatusEnum.TaskSkipped))
    );
  });
export const selectIsLoading = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.isLoading);

export const selectIsTestLoading = createSelector(
  selectTestItemTreeFeature,
  selectModeratedFeature,
  (testRunnerState, moderatedState) => testRunnerState.isTestLoading && moderatedState.isTestLoading,
);

export const selectIsTestFinished = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) =>
    testRunnerState.testInfo?.status === TestStatusEnum.Completed ||
    (StartSessionStatusEnum.Success !== testRunnerState.testRunnerStartData?.startSessionStatus &&
      StartSessionStatusEnum.PreviewMode !== testRunnerState.testRunnerStartData?.startSessionStatus &&
      StartSessionStatusEnum.AlreadyStarted !== testRunnerState.testRunnerStartData?.startSessionStatus),
);
export const selectIsVideoLoading = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.isVideoLoading || testRunnerState.extensionStatus === ExtensionStatusEnum.UploadingVideoInProgress,
);
export const selectIsWebsiteInProgress = (isWebsite: boolean): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(selectTestItemTreeFeature, (testRunnerState) => {
    return isWebsite && (!testRunnerState.isExtensionInstalled || testRunnerState?.extensionStatus !== ExtensionStatusEnum.TaskCompleted);
  });
export const selectIsWrongBrowser = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) =>
    ![
      FabDictionaryItemSystemNameEnum.Edge,
      FabDictionaryItemSystemNameEnum.Opera,
      FabDictionaryItemSystemNameEnum.Chrome,
      FabDictionaryItemSystemNameEnum.YandexBrowser,
    ].includes(testRunnerState.testRunnerStartData?.browserSystemName ?? FabDictionaryItemSystemNameEnum.Undefined) &&
    testRunnerState.testRunnerStartData?.test?.type === TestTypeEnum.WebSite &&
    !testRunnerState.testRunnerStartData?.isMobileDevice,
);
export const selectIsWrongDesktopDevice = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) =>
    !!testRunnerState.testRunnerStartData?.isMobileDevice && testRunnerState.testRunnerStartData?.test?.type === TestTypeEnum.WebSite,
);
export const selectIsWrongMobileDevice = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) =>
    !testRunnerState.testRunnerStartData?.isMobileDevice && testRunnerState.testRunnerStartData?.test?.type === TestTypeEnum.Mobile,
);
export const selectCameraDeviceId = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.cameraDeviceId);
export const selectPromoCodeByTaskId = (testItemId?: string): MemoizedSelector<object, string | null, DefaultProjectorFn<string | null>> =>
  createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.promoCodes[testItemId ?? ''] ?? null);
export const selectLogicEnvironment = createSelector(
  selectTestItemTreeFeature,
  selectAllTestItemStatistics,
  selectCurrentBrowserInfo,
  selectCurrentOsInfo,
  selectTestItems,
  (testRunnerState, testItemStatistics, browserInfo, osInfo, testItems) =>
    ({
      browserInfo,
      osInfo,
      testInfo: testRunnerState.testInfo,
      testItems,
      testItemStatistics,
      testRunnerStartData: testRunnerState.testRunnerStartData,
    }) as LogicEnvironmentParams,
);
export const selectMicroPhoneDeviceId = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.microphoneDeviceId);

export const selectRedirectUrl = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.redirectUrl);

export const selectStartSessionStatus$ = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.testRunnerStartData?.startSessionStatus ?? null,
);

export const selectPreviousPageId = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.testInfo?.stack?.slice(-1)?.[0] ?? null,
);
export const selectRootTestItem = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.testRunnerStartData?.test?.rootGroup ?? null,
);
export const selectTestInfo = createSelector(selectTestItemTreeFeature, (testRunnerState) => testRunnerState.testInfo);
export const selectTestScripts = createSelector(selectTestItemTreeFeature, (testRunnerState) =>
  testRunnerState.testRunnerStartData?.test
    ? {
        customJSOnChange: testRunnerState.testRunnerStartData.test.customJSOnChange,
        customJSOnLoad: testRunnerState.testRunnerStartData.test.customJSOnLoad,
        customJSOnExit: testRunnerState.testRunnerStartData.test.customJsOnExit,
      }
    : null,
);
export const selectTestStartData = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.testRunnerStartData?.test ?? null,
);
export const selectTestRunnerStartData = createSelector(
  selectTestItemTreeFeature,
  (testRunnerState) => testRunnerState.testRunnerStartData,
);
export const selectTestCompletionProgress = createSelector(selectLayoutTestItems, selectTestInfo, (rootTestItems, testInfo) => {
  const allPagesCount = rootTestItems?.length ?? 0;
  //const completedPagesCount = [...new Set(testInfo?.stack.map((pageId) => pageId.split('&')[0] ?? ''))].length ?? 0;
  const currentPageNumber = rootTestItems.findIndex((rootItem) => testInfo?.currentPageId === rootItem.id);

  if (!testInfo || !allPagesCount) {
    return null;
  }

  if (testInfo.status === TestStatusEnum.Completed) {
    return 100;
  } else if (currentPageNumber <= 0) {
    return 0;
  }

  return Math.round((currentPageNumber * 100) / allPagesCount);
});

/*
export const selectTestCompletionProgress = createSelector(selectLayoutTestItems, selectTestInfo, (rootTestItems, testInfo) => {
  if (!testInfo) {
    return null;
  }

  const rootTestItemsCount = rootTestItems?.length ?? 0;

  if (!rootTestItemsCount) {
    return null;
  }

  if (!testInfo.currentPageId && testInfo.status !== TestStatusEnum.Completed) {
    return null;
  }

  const percentsPerPage = 100 / rootTestItemsCount;
  let pagesToGo = rootTestItemsCount;

  for (const testItem of rootTestItems || []) {
    if (testItem?.id === testInfo?.currentPageId) {
      break;
    }
    pagesToGo--;
  }

  return Math.round((rootTestItemsCount - pagesToGo) * percentsPerPage);
});
*/
