/* tslint:disable */
/* eslint-disable */
export enum TestItemConditionValueTypeEnum {
  SourceItem = 'SourceItem',
  Text = 'Text',
  Integer = 'Integer',
  FloatingPoint = 'FloatingPoint',
  DateAndTime = 'DateAndTime',
  TimeInterval = 'TimeInterval',
  Boolean = 'Boolean',
  TestCompletionStatusEnum = 'TestCompletionStatusEnum'
}
