/* tslint:disable */
/* eslint-disable */
export enum TestSessionEnvironmentFieldEnum {
  ScreenWidth = 'ScreenWidth',
  ScreenHeight = 'ScreenHeight',
  TestStartTime = 'TestStartTime',
  TestDuration = 'TestDuration',
  TestCompletionStatus = 'TestCompletionStatus',
  AnonymousId = 'AnonymousId',
  Os = 'Os',
  Browser = 'Browser',
  BrowserVersion = 'BrowserVersion',
  IsMobileDevice = 'IsMobileDevice',
  QueryString = 'QueryString'
}
