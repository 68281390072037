import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { TestItemStatistics } from 'src/api/testrunner/models/test-item-statistics';

export const testItemStatisticsAdapter: EntityAdapter<TestItemStatistics> = createEntityAdapter<TestItemStatistics>({
  selectId: (item) => item.id as string,
  sortComparer: false,
});

export interface TestItemsStatisticsState extends EntityState<TestItemStatistics> {
  lastUpdate: number;
  isUpdating: boolean;
}
