/* tslint:disable */
/* eslint-disable */
export enum OperandEnum {
  Const = 'Const',
  ConstDiapason = 'ConstDiapason',
  ConstDiapasonInteger = 'ConstDiapasonInteger',
  ConstDiapasonFloatingPoint = 'ConstDiapasonFloatingPoint',
  ConstDiapasonDateAndTime = 'ConstDiapasonDateAndTime',
  ConstDiapasonTimeInterval = 'ConstDiapasonTimeInterval',
  TestItemEnvironment = 'TestItemEnvironment',
  TestSessionEnvironment = 'TestSessionEnvironment'
}
