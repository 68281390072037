/* tslint:disable */
/* eslint-disable */
export enum TestItemResultFieldEnum {
  TestItemResult = 'TestItemResult',
  TestItemCustomAnswer = 'TestItemCustomAnswer',
  TestItemStartTime = 'TestItemStartTime',
  TestItemDuration = 'TestItemDuration',
  TestItemAnswerTime = 'TestItemAnswerTime',
  TestItemIsAnswerCorrect = 'TestItemIsAnswerCorrect',
  TestItemIsAnswerPartialCorrect = 'TestItemIsAnswerPartialCorrect',
  TestItemReadingTimeSpent = 'TestItemReadingTimeSpent',
  LogicResultTrueCount = 'LogicResultTrueCount',
  LogicResultFalseCount = 'LogicResultFalseCount',
  TestItemResultTags = 'TestItemResultTags'
}
