/**
 * Статус старта сессии
 */
export enum StartSessionStatusEnum {
  Success = 'Success',
  AlreadyStarted = 'AlreadyStarted',
  Finished = 'Finished',
  PreviewMode = 'PreviewMode',
  QuotaExhaustedError = 'QuotaExhaustedError',
  TestIsNotRunningError = 'TestIsNotRunningError',
  IncorrectDeviceTypeError = 'IncorrectDeviceTypeError',
  IncorrectBrowserTypeError = 'IncorrectBrowserTypeError',
  SapOutdatedLinkError = 'SapOutdatedLinkError',
  SapOutboundIdError = 'SapOutboundIdError',
  SapAttemptRePassingError = 'SapAttemptRePassingError',
  UniqueIdIsNullError = 'UniqueIdIsNullError',
  UniqueIdInvalidError = 'UniqueIdInvalidError',
}
