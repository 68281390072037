/* tslint:disable */
/* eslint-disable */
export enum TestItemResultTypeEnum {
  Info = 'Info',
  FreeForm = 'FreeForm',
  FirstClick = 'FirstClick',
  FirstGlance = 'FirstGlance',
  Select = 'Select',
  Matrix = 'Matrix',
  Ranking = 'Ranking',
  Number = 'Number',
  Page = 'Page',
  WebSite = 'WebSite',
  Mobile = 'Mobile',
  CardSorting = 'CardSorting',
  Kano = 'Kano',
  Scale = 'Scale',
  LayoutGroup = 'LayoutGroup',
  TaskGroup = 'TaskGroup',
  TestEndByQuota = 'TestEndByQuota',
  TestEndByCondition = 'TestEndByCondition',
  File = 'File',
}
