/* tslint:disable */
/* eslint-disable */
export enum OperatorEnum {
  Equal = 'Equal',
  GreaterOrEqual = 'GreaterOrEqual',
  LessOrEqual = 'LessOrEqual',
  Greater = 'Greater',
  Less = 'Less',
  Contains = 'Contains',
  Contained = 'Contained',
  Intersect = 'Intersect',
  Regex = 'Regex'
}
