/* tslint:disable */
/* eslint-disable */
export enum TestItemTypeEnum {
  None = 'None',
  Layout = 'Layout',
  LayoutGroup = 'LayoutGroup',
  Task = 'Task',
  TaskGroup = 'TaskGroup',
  WebLayout = 'WebLayout',
  Page = 'Page',
  WebSite = 'WebSite',
  Mobile = 'Mobile',
  Else = 'Else',
  RootItem = 'RootItem',
  FreeForm = 'FreeForm',
  FirstClick = 'FirstClick',
  FirstGlance = 'FirstGlance',
  Select = 'Select',
  Matrix = 'Matrix',
  Ranking = 'Ranking',
  CardSorting = 'CardSorting',
  Info = 'Info',
  Number = 'Number',
  Scale = 'Scale',
  TestEndByCondition = 'TestEndByCondition',
  TestEndByQuota = 'TestEndByQuota',
  Kano = 'Kano',
  File = 'File',
}
